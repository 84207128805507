import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './ProductItemV1.styles';
import { formatPrice } from '../../utils/formatPrice';
import { useCount } from '../../context/CountContext';

const ProductItemV1 = ({ maxQuantity = 4 }) => {
  const { state, dispatch } = useCount();
  const { repurchaseQuantity: quantity } = state;
  const { onetime, subscription, currentCategory, currency } = useContext(
    FunnelContext
  );

  const isSubscription = currentCategory === 'subscriptions';
  const { products } = isSubscription ? subscription : onetime;
  const product = products[quantity - 1];
  const { discounted_price: discountedPrice } = product?.checkoutData;
  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  return (
    <S.Container>
      <S.ProductWrapper>
        <S.ProductImage>
          {products[quantity]?.checkoutData?.image && (
            <img
              src={products[quantity].checkoutData.image}
              alt={products[quantity].display_title}
            />
          )}
          <S.QuantityCircle>{quantity}</S.QuantityCircle>
        </S.ProductImage>
        <S.ProductInfo>
          <S.ProductName>{products[quantity].display_title}</S.ProductName>
          <S.Quantity>
            <S.Decrement
              onClick={() => {
                quantity > 1 && dispatch({ type: 'decrement' });
              }}
            >
              -
            </S.Decrement>
            <S.QuantityNumber>{quantity}</S.QuantityNumber>
            <S.Increment
              onClick={() => {
                quantity < maxQuantity && dispatch({ type: 'increment' });
              }}
            >
              +
            </S.Increment>
          </S.Quantity>
        </S.ProductInfo>
      </S.ProductWrapper>
      <S.Price>{formatPrice(discountedPrice, priceSettings)}</S.Price>
    </S.Container>
  );
};

export default ProductItemV1;
