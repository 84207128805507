import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './ProductItemV2.styles';
import { formatPrice } from '../../utils/formatPrice';
import { useCount } from '../../context/CountContext';

const ProductItemV2 = ({ discountBadge, showFullPrice, maxQuantity = 4 }) => {
  const { state, dispatch } = useCount();
  const { repurchaseQuantity: quantity } = state;
  const { onetime, subscription, currentCategory, currency } = useContext(
    FunnelContext
  );

  const isSubscription = currentCategory === 'subscriptions';
  const { products } = isSubscription ? subscription : onetime;
  const product = products[quantity - 1];
  const {
    perceived_rrp,
    discounted_price: discountedPrice
  } = product?.checkoutData;
  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.Line>
            <S.ProductWrapper>
              <S.ProductImage>
                {discountBadge ? (
                  <S.DiscountImage src={discountBadge} alt="discount" />
                ) : null}
                {product?.checkoutData?.image && (
                  <img
                    src={product.checkoutData.image}
                    alt={product.display_title}
                  />
                )}
                <S.QuantityCircle>{quantity}</S.QuantityCircle>
              </S.ProductImage>
              <S.ProductInfo>
                <S.ProductName>{product?.display_title}</S.ProductName>
                <S.Category>One-time purchase</S.Category>
              </S.ProductInfo>
            </S.ProductWrapper>
            <S.Prices>
              <div>
                {formatPrice(discountedPrice, priceSettings)}
                {showFullPrice && (
                  <div>
                    <s>{formatPrice(perceived_rrp, priceSettings)}</s>
                  </div>
                )}
              </div>
              {currentCategory === 'subscription' && (
                <div>
                  <s>{formatPrice(perceived_rrp, priceSettings)}</s>
                </div>
              )}
            </S.Prices>
          </S.Line>
          <S.Quantity>
            <S.QuantityTitle>Quantity</S.QuantityTitle>
            <S.Decrement
              onClick={() => {
                quantity > 1 && dispatch({ type: 'decrement' });
              }}
            >
              -
            </S.Decrement>
            <S.QuantityNumber>{quantity}</S.QuantityNumber>
            <S.Increment
              onClick={() => {
                quantity < maxQuantity && dispatch({ type: 'increment' });
              }}
            >
              +
            </S.Increment>
          </S.Quantity>
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default ProductItemV2;
