import React from 'react';
import ProductItemV1 from './ProductItemV1';
import ProductItemV2 from './ProductItemV2';
import ProductItemResult from './ProductItemResult';

const ProductItem = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version1':
      return <ProductItemV1 {...props} />;
    case 'version2':
      return <ProductItemV2 {...props} />;
    default:
    case 'result':
      return <ProductItemResult {...props} />;
  }
};

export default ProductItem;
