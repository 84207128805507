import styled, { keyframes } from 'styled-components';

const glower = keyframes`
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    border-radius: 4px;
    background: linear-gradient(
      45deg,
      #fb0094,
      #0000ff,
      #001c72,
      #59c059,
      #ff0000,
      #fb0094,
      #4141ec,
      #001c72,
      #59c059,
      #ff0000
    );
    background-size: 400%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;
    animation: ${glower} 20s linear infinite;
    filter: blur(1px);
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  background: linear-gradient(180deg, #ffffff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  border-radius: 4px;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const ProductImage = styled.div`
  max-width: 92px;
  max-height: 92px;
  padding: 8px;
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  position: relative;

  img {
    max-width: 100%;
  }

  @media (max-width: 500px) {
    max-width: 80px;
    max-height: 80px;
    padding: 2px;
  }
`;
export const DiscountImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;

  @media (max-width: 500px) {
    width: 25px;
    height: 25px;
  }
`;
export const QuantityCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #001c72;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 700;
  font-size: 16px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const ProductName = styled.div`
  font-weight: 400;
  color: #001c72;
`;

export const Category = styled.div`
  font-weight: 400;
  line-height: 140%;
  margin-top: 4px;
  color: #33498e;
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
`;

export const QuantityTitle = styled.span`
  margin-right: 8px;
`;

export const QuantityNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 31px;
  background: #ffffff;
  border-width: 1px 0;
  border-style: solid;
  border-color: #ccd2e3;
  user-select: none;
`;

export const Decrement = styled.button`
  width: 32px;
  height: 31px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #ccd2e3;
  border-radius: 40px 0 0 40px;
  background-color: #ffffff;
  cursor: pointer;

  svg {
    fill: #6677aa;
    width: 8px;

    &:hover {
      fill: #001c72;
    }
  }
`;

export const Increment = styled.button`
  width: 32px;
  height: 31px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: #ccd2e3;
  border-radius: 0 40px 40px 0;
  background-color: #ffffff;
  cursor: pointer;

  svg {
    fill: #6677aa;
    width: 10px;

    &:hover {
      fill: #001c72;
    }
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #001c72;

  & s {
    font-weight: 400;
  }

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;
